var aboutSwiper = new Swiper(".why-us__slider", {
  spaceBetween: 30,
  speed: 1000,
  autoplay: {
    delay: 3500,
    disableOnInteraction: false,
  }
});


