document.onreadystatechange = function() {
    if (document.readyState !== "complete") {
        // Показываем прелоадер, пока страница загружается
        document.getElementById('loader-wrapper').style.display = 'flex';
        document.querySelector('.site-container').classList.add('site-container--hidden');
    } else {
        // Скрываем прелоадер и показываем контент после полной загрузки страницы
        document.getElementById('loader-wrapper').style.display = 'none';
        document.querySelector('.site-container').classList.remove('site-container--hidden');
    }
};