import './components/burger.js';
import './components/fixed-header.js';
import './components/cokkie.js';
import './components/disable-scroll.js';
import './components/enable-scroll.js';
import './components/preloader.js';
import './components/tabs.js';
import './components/slider.js';
import './components/accordion.js';
import './components/modal-form-vavidation.js';
import './components/contact-form.js';
import './components/replace.js';
