let scrollPosition = 0;

document.querySelectorAll(".openPopupBtn").forEach(button => {
  button.addEventListener("click", function () {
    scrollPosition = window.scrollY;
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add("dis-scroll");

    document.getElementById("popup").classList.add("show");
    document.querySelector(".popup-content").classList.add("show");
  });
});

document.querySelector(".close").addEventListener("click", function () {
  closePopup();
});

window.addEventListener("click", function (event) {
  if (event.target === document.getElementById("popup")) {
    closePopup();
  }
});

function closePopup() {
  document.querySelector(".popup-content").classList.remove("show");
  document.getElementById("popup").classList.remove("show");
  document.body.classList.add("disable-scroll");
  setTimeout(function () {
    document.body.classList.remove("dis-scroll");
    document.body.classList.remove("disable-scroll");
    document.body.style.top = '';
    window.scrollTo(0, scrollPosition);

    document.getElementById("modalForm").reset();
    document.getElementById("thankYouMessage").classList.add("hidden");
    document.getElementById("modalForm").style.display = "block";
  }, 300);
}


document.getElementById("modalForm").addEventListener("submit", function (event) {
  event.preventDefault();

  if (!validateForm()) {
    return;
  }

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "modal-email.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 200) {
      document.getElementById("modalForm").classList.remove("show");
      setTimeout(function () {
        document.getElementById("modalForm").style.display = "none";
        document.getElementById("thankYouMessage").classList.remove("hidden");
      }, 300);
    }
  };
  var formData = new FormData(document.getElementById("modalForm"));
  xhr.send(new URLSearchParams(formData).toString());
});

function validateForm() {
  var nameInput = document.getElementById("modalName");
  var phoneInput = document.getElementById("modalPhone");
  var isValid = true;

  nameInput.classList.remove("invalid-input");
  phoneInput.classList.remove("invalid-input");

  document.getElementById("modalNameError").innerHTML = "";
  document.getElementById("modalPhoneError").innerHTML = "";

  if (nameInput.value.trim() === "") {
    nameInput.classList.add("invalid-input");
    document.getElementById("modalNameError").innerHTML = "Name is required";
    isValid = false;
  }

  if (phoneInput.value.trim() === "") {
    phoneInput.classList.add("invalid-input");
    document.getElementById("modalPhoneError").innerHTML = "Phone is required";
    isValid = false;
  }

  return isValid;
}

