document.addEventListener("DOMContentLoaded", function() {
  const tabs = document.querySelectorAll(".tab");
  const tabContents = document.querySelectorAll(".tab-content");

  tabs.forEach(tab => {
    tab.addEventListener("click", () => {
      const targetTab = tab.getAttribute("data-tab");

      tabs.forEach(tab => {
        tab.classList.remove("active");
      });

      tabContents.forEach(content => {
        content.classList.remove("active");
        if (content.getAttribute("data-tab") === targetTab) {
          content.classList.add("active");
        }
      });

      tab.classList.add("active");
    });
  });

  // Открытие первого таба при загрузке страницы
  tabs[0].click();
});
