document.getElementById("contactForm").addEventListener("submit", function(event) {
  event.preventDefault();

  if (!validateForm()) {
    return;
  }

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "email.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        showThankYouPopup();
        document.getElementById("contactForm").reset();
      } else {
        console.error("Request failed. Status: " + xhr.status + ", Response: " + xhr.responseText);
      }
    }
  };

  xhr.onerror = function() {
    console.error("Network error occurred during XHR request.");
  };

  var formData = new FormData(document.getElementById("contactForm"));
  xhr.send(new URLSearchParams(formData).toString());
});

function showThankYouPopup() {
  var overlay = document.getElementById("overlay");
  var thankYouPopup = document.getElementById("thankYouPopup");

  overlay.style.display = "block";
  thankYouPopup.style.display = "block";
}

function closePopup() {
  var overlay = document.getElementById("overlay");
  var thankYouPopup = document.getElementById("thankYouPopup");

  overlay.style.display = "none";
  thankYouPopup.style.display = "none";
}

function validateForm() {
  var nameInput = document.getElementById("name");
  var phoneInput = document.getElementById("phone");
  var isValid = true;

  nameInput.classList.remove("invalid-input");
  phoneInput.classList.remove("invalid-input");

  document.getElementById("nameError").innerHTML = "";
  document.getElementById("phoneError").innerHTML = "";

  if (nameInput.value.trim() === "") {
      nameInput.classList.add("invalid-input");
      document.getElementById("nameError").innerHTML = "Name is required";
      isValid = false;
  }

  if (phoneInput.value.trim() === "") {
      phoneInput.classList.add("invalid-input");
      document.getElementById("phoneError").innerHTML = "Phone is required";
      isValid = false;
  }

  return isValid;
}

document.getElementById("closeButton").addEventListener("click", closePopup);


